import api from '../../../apis';
import { errorParser } from '../../../utils';

import { REGISTER } from '../../types';

const { FETCH_REGISTER_FAILED, FETCH_REGISTER_REQUEST, FETCH_REGISTER_SUCCESS } = REGISTER;

export const fetchRegisterRequest = () => ({
  type: FETCH_REGISTER_REQUEST
});

export const fetchRegisterSuccess = (auth) => ({
  type: FETCH_REGISTER_SUCCESS,
  payload: auth
});

export const fetchRegisterFail = (error) => ({
  type: FETCH_REGISTER_FAILED,
  payload: error
});

export const fetchDataRegister = (payload) => async (dispatch) => {
  try {
    dispatch(fetchRegisterRequest());
    const { data } = await api.auth.register(payload);
    dispatch(fetchRegisterSuccess(data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchRegisterFail(message));
  }
};
